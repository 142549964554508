import axios from "axios";
import { authHeader } from "./auth.header";
import { _authHeader } from "./auth._header";
import helpers from "@/helpers/helper";

const API_URL = helpers.API_ENDPOINT;
const API_URL_ALT = helpers.API_ENDPOINT_ALT;

class SalesService {
  getSalesperson() {
    return axios.get(API_URL + 'salesperson', { headers: _authHeader(), }).then((resp) => {
      return resp.data;
    })
  }

  getSales(data) {
    var page = data ? data.page : 1;

    const params = new URLSearchParams();
    params.append('page', page);
    params.append('size', data.size);
    params.append('search', data.search);
    params.append('start_date', data.start_date != null ? data.start_date : '');
    params.append('end_date', data.end_date != null ? data.end_date : '');

    return axios.get(API_URL + 'sale/sales', { params: params, headers: _authHeader(), }).then((resp) => {
      return resp.data;
    })
  }
  getSummary(data) {
    var page = data ? data.page : 1;

    const params = new URLSearchParams();
    params.append('page', page);
    params.append('size', data.size);
    params.append('search', data.search);
    params.append('start_date', data.start_date != null ? data.start_date : '');
    params.append('end_date', data.end_date != null ? data.end_date : '');

    return axios.get(API_URL + 'sale/summary', { params: params, headers: _authHeader(), }).then((resp) => {
      return resp.data;
    })
  }

  getAccountValues(data) {
    var page = data ? data.page : 1;

    const params = new URLSearchParams();
    params.append('page', page);
    params.append('size', data.size);
    params.append('search', data.search);
    params.append('start_date', data.start_date != null ? data.start_date : '');
    params.append('end_date', data.end_date != null ? data.end_date : '');

    return axios.get(API_URL + 'sale/account-values', { params: params, headers: _authHeader(), }).then((resp) => {
      return resp.data;
    })
  }

  getSalesProjections(data) {
    var page = data ? data.page : 1;

    const params = new URLSearchParams();
    params.append('page', page);
    params.append('size', data.size);
    params.append('search', data.search);
    params.append('start_date', data.start_date != null ? data.start_date : '');
    params.append('end_date', data.end_date != null ? data.end_date : '');

    return axios.get(API_URL + 'sale/projection', { params: params, headers: _authHeader(), }).then((resp) => {
      return resp.data;
    })
  }

  getBreakEven(data) {
    return axios.post(API_URL + 'sale/break-even', data, { headers: _authHeader() }).then((resp) => {
      return resp.data;
    })
  }

  getAutoGeneration(data) {

    const params = new URLSearchParams();
    params.append('start_date', data.start_date);
    params.append('end_date', data.end_date);
    params.append('key', 'abcdefZemoIntergrateiCPmainApp');
    return axios.get(API_URL_ALT + 'account-value-params', { params: params, headers: _authHeader() }).then((resp) => {
      return resp.data;
    })
  }

  getEmailMatrix(data) {
    var page = data ? data.page : 1;

    const params = new URLSearchParams();
    params.append('page', page);
    params.append('size', data.size);
    params.append('search', data.search);
    params.append('start_date', data.start_date != null ? data.start_date : '');
    params.append('end_date', data.end_date != null ? data.end_date : '');

    return axios.get(API_URL + 'sale/email-metrix', { params: params, headers: _authHeader(), }).then((resp) => {
      return resp.data;
    })
  }
  getClientExperiece(data) {
    var page = data ? data.page : 1;

    const params = new URLSearchParams();
    params.append('page', page);
    params.append('size', data.size);
    params.append('search', data.search);
    params.append('start_date', data.start_date != null ? data.start_date : '');
    params.append('end_date', data.end_date != null ? data.end_date : '');

    return axios.get(API_URL + 'sale/client-experience', { params: params, headers: _authHeader(), }).then((resp) => {
      return resp.data;
    })
  }  
  getClientExperieceView(id) {
    return axios.get(API_URL + 'sale/client-experience/' + 2, {headers: _authHeader(), }).then((resp) => {
      return resp.data;
    })
  }
  getIteration(data){
    var page = data ? data.page : 1;

    const params = new URLSearchParams();
    params.append('page', page);
    params.append('size', data.size);
    params.append('search', data.search);
    params.append('start_date', data.start_date != null ? data.start_date : '');
    params.append('end_date', data.end_date != null ? data.end_date : '');
    return axios.post(API_URL + 'sale/client-experience', data, {params: params, headers: _authHeader(), }).then((resp) => {
      return resp.data;
    })
  }

}

export default new SalesService();