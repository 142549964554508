import zemoService from "@/services/zemo.service";

export const zemo   = {
    namespaced      : true,
    state       : {
        statistics : {
            team    : {},
            pipeline : {}
        }
    },
    actions: {
        getTeam({ commit }, data) {
            return zemoService.getTeam(data).then((data) => {
                commit("team", data);
                return Promise.resolve(data);
            });
        },
        postTeam({ commit }, data) {
            return zemoService.postTeam(data).then((data) => {
                commit("user", data);
                return Promise.resolve(data);
            });
        },
        getStatistics({ commit }) {
            return zemoService.getStatistics().then((response) => {
                commit("statistics", response.data.data);
                return Promise.resolve(response);
            });
        }
    },
    mutations: {
        team(state, data) {
            state.team = data;
        },
        user(state, data) {
            state.user = data;
        },
        statistics(state, data) {
            state.statistics = data
            state.statistics.team = data.team
            state.statistics.pipeline = data.pipeline
        }
    },
};