import SettingsService from "@/services/settings.service";

export const settings = {

    namespaced: true,

    state: {},

    actions: {
        getAPIKeys({ commit }, data) {
            return SettingsService.getAPIKeys(data).then((data) => {
                commit("api_keys", data);
                return Promise.resolve(data);
            });
        },
    },

    mutations: {
        api_keys(state, data) {
            state.api_keys = data;
        },
    },
}