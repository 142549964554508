import AnalyticsService from "@/services/analytiics/analytics";

export const analytics = {
    namespaced: true,

    state: {},

    actions: {
        getIndividualSales({ commit }, data) {
            return AnalyticsService.getIndividualSales(data).then((data) => {
                commit("individual_sales", data);
                return Promise.resolve(data);
            });
        },
        getConvertionRate({ commit }, data) {
            return AnalyticsService.getConvertionRate(data).then((data) => {
                commit("convertion_rate", data);
                return Promise.resolve(data);
            });
        },
        getPipeleine({ commit }, data) {
            return AnalyticsService.getPipeleine(data).then((data) => {
                commit("pipeline", data);
                return Promise.resolve(data);
            });
        },


        
    },

    mutations: {
        individual_sales(state, data) {
            state.individual_sales = data;
        },
        convertion_rate(state, data) {
            state.convertion_rate = data;
        },
        pipeline(state, data) {
            state.pipeline = data;
        },
    }


    

}