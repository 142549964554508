<template>
  <div class="error-box">
    <h1>401</h1>
    <h3 class="h2 mb-3">
      <i class="fas fa-exclamation-circle"></i> Oops! looks like...
    </h3>
    <p class="h4 font-weight-normal">
      You,re NOT AUTHORIZED to access any module on this Platform
    </p>
    <router-link to="#" @click="Logout" class="btn btn-primary">Back to Sign in</router-link>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    Logout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/signin");
    },
  },
};
</script>

<style>
.content {
  padding: 65px 0 65px 0;
  position: relative;
}

p {
  margin: 0 0 20px;
  font-size: 18px;
  line-height: 28px;
  font-weight: 300;
}

p.large {
  font-size: 20px;
  line-height: 30px;
  font-weight: 300;
  margin-bottom: 55px;
}

img {
  max-width: 100%;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit;
  font-weight: 400 !important;
  line-height: 1.1;
  color: inherit;
}

h1 {
  font-size: 48px;
}

.centered,
p.large.centered {
  text-align: center;
}
</style>