<template>
  <div class="main-wrapper login-body">
    <div class="login-wrapper">
      <div class="container">
        <img
          class="img-fluid logo-dark"
          src="/assets/img/logo.png"
          alt="Logo"
        />
        <el-card class="loginbox">
          <el-form
            :model="user"
            size="large"
            ref="user"
            label-position="top"
            inline
            status-icon
          >
            <el-row class="p-3">
              <el-col
                :xs="24"
                :sm="24"
                :md="24"
                :lg="24"
                :xl="24"
                class="text-center p-2"
              >
                <h3>Change Password</h3>
              </el-col>
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <el-form-item
                  label=""
                  prop="password"
                  :rules="[
                    {
                      required: true,
                      message: 'New password is required',
                      trigger: ['blur'],
                    },
                  ]"
                >
                  <el-input
                    v-model="user.password"
                    type="password"
                    autocomplete="off"
                    placeholder="Enter new password"
                  />
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <el-form-item
                  label=""
                  prop="password_confirmation"
                  :rules="[
                    {
                      required: true,
                      message: 'Confirm password is required',
                      trigger: ['blur'],
                    },
                  ]"
                >
                  <el-input
                    v-model="user.password_confirmation"
                    type="password"
                    autocomplete="off"
                    placeholder="Confirm password"
                  />
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <el-form-item label="">
                  <el-button
                    type="primary"
                    class="w-100"
                    @click="submit('user')"
                    :loading="loading"
                    >Set Password</el-button
                  ></el-form-item
                >
              </el-col>
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <div class="login-or">
                  <span class="or-line"></span>
                  <span class="span-or">or</span>
                </div>
                <div class="text-center dont-have">
                  Already have an account yet?
                  <router-link to="/signin">Sign In</router-link>
                </div>
              </el-col>
            </el-row>
          </el-form>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import authService from "@/services/auth.service";

export default {
  data() {
    return {
      props: ["token"],
      loading: false,
      message: "",
      user: {},
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() {
    this.token = this.$route.params.token;
    if (this.loggedIn) {
      this.$router.push("/");
    }
  },
  methods: {
    submit(validate) {
      this.$refs[validate].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.user.token = this.token;
          let data = this.user;

          authService.resetPassword(data).then(
            (response) => {
              this.loading = false;
              this.$notify({
                title: "Password changed",
                message: response.data.message,
                type: "warning",
              });
              this.$router.push("/signin");
            },
            (error) => {
              this.loading = false;
              this.message =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
              this.$notify({
                title: "Error",
                message: this.message,
                type: "error",
              });
            }
          );
        } else {
          return false;
        }
      });
    },
  },
};
</script>