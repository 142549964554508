import helpers from '@/helpers/helper';
import Axios from './Axios';
import axios from 'axios';
import { _authHeader } from "./auth._header";

const API_URL = helpers.API_ENDPOINT;

class contactUsService {
    // getContactUs(data) {
    //   return Axios({
    //     method  : 'GET',
    //     url     : API_URL + 'blog/contact-us' + '?page=' + data.page,
    //     data    : data,
    //   });
    // }
    // getContactUs(data){
    //   return axios.get(API_URL + "blog/contact-us" + '?page=' + data.page, { headers: _authHeader() });
      
    // }
    // patchContactUs(data) {
    //   return Axios({
    //     method  : 'PATCH',
    //     url     : API_URL + 'blog/contact-us/' + data.id,
    //     data    : data,
    //   });
    // }
    getContactUs(data) {
      const params = new URLSearchParams({
        page: data.page,
        size: data.size,
        search: data.search,
        start_date: data.start_date,
        end_date: data.end_date,
        type: data.type,  
      }).toString();
  
      return Axios({
        method: 'GET',
        url: `${API_URL}blog/contact-us?${params}`,
        headers: _authHeader(),  
      });
    }
  
    patchContactUs(data) {
      return Axios({
        method: 'PATCH',
        url: API_URL + 'blog/contact-us/' + data.id,
        headers: _authHeader(), 
        data: data,
      });
    }
  }
export default new contactUsService();