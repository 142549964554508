import TicketService from "@/services/helpDesk/ticketing/ticket.service";

export const ticketing = {
    namespaced: true,

    state: {},

    actions: {
        getTicket({ commit }, data) {
            return TicketService.getTicket(data).then((data) => {
                commit("ticket", data);
                return Promise.resolve(data);
            });
        },
        getClient({ commit }, data) {
            return TicketService.getClient(data).then((data) => {
                commit("client", data);
                return Promise.resolve(data);
            });
        },
        getCategory({ commit }, data) {
            return TicketService.getCategory(data).then((data) => {
                commit("category", data);
                return Promise.resolve(data);
            });
        }, 
        getStatus({ commit }, data) {
            return TicketService.getStatus(data).then((data) => {
                commit("status", data);
                return Promise.resolve(data);
            });
        },  
        getResponse({ commit }, data) {
            return TicketService.getResponse(data).then((data) => {
                commit("response", data);
                return Promise.resolve(data);
            });
        }, 
        getReplies({ commit }, data) {
            return TicketService.getReplies(data).then((data) => {
                commit("replies", data);
                return Promise.resolve(data);
            });
        },  
    },
    mutations: {
        ticket(state, data) {
            state.ticket = data;
        },
        client(state, data) {
            state.client = data;
        },
        category(state, data) {
            state.category = data;
        },
        status(state, data) {
            state.status = data;
        },
        response(state, data) {
            state.response = data;
        },
        replies(state, data) {
            state.replies = data;
        },
    }
}