import axios from "axios";
import { _authHeader } from "./auth._header";
import helpers from "@/helpers/helper";
import router from "@/router";
import store from "@/store";

const API_ENDPOINT = helpers.API_ENDPOINT;
const API_URL = helpers.API_ENDPOINT;


axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response === undefined || error.response.status === 429) {
      console.log("Network error");
      // store.dispatch("auth/logout");
      // router.push("/Signin");
      return false;
    } else if (error.response.status === 401) {
      store.dispatch("auth/logout");
      router.push("/Signin");
      return false;
    } else if (error.response.status === 403) {
      console.log("Unauthorized to do this action");
    }
    return Promise.reject(error);
  }
);

class apiService {
  // start: company-branches
  saveClient(data) {
    if (data.id) {
      return axios.patch(API_URL + "company/" + data.id, data, {
        headers: _authHeader(),
      });
    } else {
      return axios.post(API_URL + "company", data, {
        headers: _authHeader(),
      });
    }
  }
  deleteClient(id) {
    return axios.delete(API_URL + "company" + id, { headers: _authHeader() });
  }
  // start: company-branches
  // start: category
  saveCategory(data) {
    if (data.id) {
      return axios.put(API_URL + "blog/category/" + data.id, data, {
        headers: _authHeader(),
      });
    } else {
      return axios.post(API_URL + "blog/category", data, {
        headers: _authHeader(),
      });
    }
  }
  deleteCategory(id) {
    return axios.delete(API_URL + "blog/category/" + id, {
      headers: _authHeader(),
    });
  }
  // start: category
  // start: blog 
  addBlog(data) {

    // console.log("Blog data", data)
    // const hasIdField = data.has("id");
    if (data.id) {
      const idValue = data.get("id");
      console.log("Blog data", data)
      return axios.patch(API_URL + "blog/posts/" + idValue, data, {
        headers: _authHeader(),
      });

      // console.log("data to edit", data)
      // if (data.id) {

      //   console.log('data before editing in endpoint', data)
      //   return axios
      //     .post(API_URL + "blog/posts/" + data.id, data, {
      //       headers: _authHeader(),
      //     })
      //     .then((response) => response.data)
      //     .catch((error) => {
      //       console.error("Error updating testimonial:", error);
      //       throw error;
      //     });
    } else {
      return axios.post(API_URL + "blog/posts", data, {
        headers: _authHeader(),
      });
    }
  }

  addTestimony(data) {
    if (data.id) {
      return axios
        .put(API_URL + "testimonials/" + data.id, data)
        .then((response) => response.data)
        .catch((error) => {
          console.error("Error updating testimonial:", error);
          throw error;
        });
    } else {
      const formData = new FormData();
      formData.append("company_name", data.company_name);
      formData.append("admin", data.admin);
      formData.append("rating_stars", data.rating_stars);
      formData.append("content", data.content);

      if (data.icon !== null && data.icon !== undefined) {
        formData.append("icon", data.icon);
      }

      return axios
        .post(API_URL + "testimonials", formData)
        .then((response) => response.data)
        .catch((error) => {
          console.error("Error creating testimonial:", error);
          throw error;
        });
    }
  }

  // Delete a testimonial
  deleteTestimonial(testimonialId) {
    return axios
      .delete(API_URL + "testimonials/" + testimonialId)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }


  deleteBlog(id) {
    console.log("Delete This post", id);
    return axios.delete(API_URL + "blog/posts/" + id, {
      headers: _authHeader(),
    });
  }
  // stendart: 
  //START: NEW------------------>
  // START: sales
  saveSale(data) {
    if (data.id) {
      return axios.patch(API_URL + "sale/sales/" + data.id, data, {
        headers: _authHeader(),
      });
    } else {
      return axios.post(API_URL + "sale/sales", data, {
        headers: _authHeader(),
      });
    }
  }
  deleteSales(id) {
    return axios.delete(API_URL + "sale/sales/" + id, { headers: _authHeader() });
  }
  saveAccountValue(data) {
    if (data.id) {
      return axios.patch(API_URL + "sale/account-values/" + data.id, data, {
        headers: _authHeader(),
      });
    } else {
      return axios.post(API_URL + "sale/account-values", data, {
        headers: _authHeader(),
      });
    }
  }
  deleteAccountValue(id) {
    return axios.delete(API_URL + "sale/account-values/" + id, { headers: _authHeader() });
  }
  saveProjection(data) {
    if (data.id) {
      return axios.patch(API_URL + "sale/projection/" + data.id, data, {
        headers: _authHeader(),
      });
    } else {
      return axios.post(API_URL + "sale/projection", data, {
        headers: _authHeader(),
      });
    }
  }
  deleteProjection(id) {
    return axios.delete(API_URL + "sale/projection/" + id, { headers: _authHeader() });
  }
  saveMatix(data) {
    console.log("Matrix Value", data)
    if (data.id) {
      return axios.patch(API_URL + "sale/email-metrix/" + data.id, data, {
        headers: _authHeader(),
      });
    } else {
      return axios.post(API_URL + "sale/email-metrix", data, {
        headers: _authHeader(),
      });
    }
  }
  deleteMatix(id) {
    return axios.delete(API_URL + "sale/email-metrix/" + id, { headers: _authHeader() });
  }

  saveClientExpenrience(data) {
    console.log("Matrix Value", data)
    if (data.id) {
      return axios.patch(API_URL + "sale/client-experience/" + data.id, data, {
        headers: _authHeader(),
      });
    } else {
      return axios.post(API_URL + "sale/client-experience", data, {
        headers: _authHeader(),
      });
    }
  }
  deleteClientExpenrience(id) {
    return axios.delete(API_URL + "sale/client-experience/" + id, { headers: _authHeader() });
  }
  resetSales() {
    return axios.get(API_URL + "sale/reset", { headers: _authHeader() });
  }
  // END: sales

  //START: Settings
  // start: api_key
  saveAPIkey(data) {
    if (data.id) {
      return axios.put(API_URL + "api_key/" + data.id, data, {
        headers: _authHeader(),
      });
    } else {
      return axios.post(API_URL + "api_key", data, {
        headers: _authHeader(),
      });
    }
  }
  deleteAPIKey(data) {
    return axios.delete(API_URL + "api_key/" + data.id, {
      headers: _authHeader(),
    });
  }
  // end: api_key
  //start: uploads
  downloadClientsReport(abc) {
    return axios.post(API_URL + "download-deals-template", abc, {
      headers: _authHeader(),
      responseType: 'blob' // Set the response type to blob
    })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'zemo deals template.xlsx'); // Set the file name here
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => {
        console.error('Error downloading Excel file:', error);
      });
  }
  importDeals(user, formatDate) {
    console.log("Assigne user", user);
    return axios.post(API_URL + "imports-deals?assigned_to=" + user, formatDate, {
      headers: _authHeader(),
    }).then((response) => {
      return response;
    });;
  }
  //end: uploads
  //start" users
  addUsser(data) {
    data.name = data.first_name + " " + data.last_name;



    console.log("ooooobjest", data);
    if (data.id) {
      return axios.put(API_URL + "users/" + data.id, data, {
        headers: _authHeader(),
      });
    } else {
      return axios.post(API_URL + "users", data, { headers: _authHeader() });
    }
  }
  deleteUser(data) {
    return axios.delete(API_URL + "users/" + data.id, { headers: _authHeader() });
  }
  //end" users
  //start: roles & permission
  addPermission(data) {
    if (data.id) {
      return axios.put(API_URL + "permissions/" + data.id, data, {
        headers: _authHeader(),
      });
    } else {
      return axios.post(API_URL + "permissions", data, { headers: _authHeader() });
    }
  }
  destroyPermission(data) {
    return axios.delete(API_URL + "permissions/" + data.id, { headers: _authHeader() });
  }
  addModel(data) {
    if (data.id) {
      return axios.put(API_URL + "models/" + data.id, data, {
        headers: _authHeader(),
      });
    } else {
      return axios.post(API_URL + "models", data, { headers: _authHeader() });
    }
  }
  destroyModel(data) {
    return axios.delete(API_URL + "models/" + data.id, { headers: _authHeader() });
  }
  addRole(data) {
    console.log("This is the data submited", data);
    if (data.id) {
      return axios.put(API_URL + "roles/" + data.id, data, {
        headers: _authHeader(),
      });
    } else {
      return axios.post(API_URL + "roles", data, { headers: _authHeader() });
    }
  }
  destroyRole(data) {
    return axios.delete(API_URL + "roles/" + data.id, { headers: _authHeader() });
  }
  //end: roles & permission
  //start: deal
  addDeal(data) {
    if (data.id) {
      return axios.put(API_URL + "company/" + data.id, data, {
        headers: _authHeader(),
      });
    } else {
      return axios.post(API_URL + "deal", data, { headers: _authHeader() });
    }
  }
  destroyDeal(id) {
    return axios.delete(API_URL + "company/" + id, { headers: _authHeader() });
  }
  addNote(data) {
    if (data.id) {
      return axios.put(API_URL + "deal-note/" + data.id, data, {
        headers: _authHeader(),
      });
    } else {
      return axios.post(API_URL + "deal-note", data, { headers: _authHeader() });
    }
  }
  destroyNote(id) {
    return axios.delete(API_URL + "deal-note/" + id, { headers: _authHeader() });
  }
  addTask(data) {
    if (data.id) {
      console.log("Delete  >>>>this:", data);
      return axios.put(API_URL + "deal-task/" + data.id, data, {
        headers: _authHeader(),
      });
    } else {
      return axios.post(API_URL + "deal-task", data, { headers: _authHeader() });
    }
  }
  destroyTask(id) {
    return axios.delete(API_URL + "deal-task/" + id, { headers: _authHeader() });
  }
  addEmail(data) {
    if (data.id) {
      return axios.put(API_URL + "deal-emails/" + data.id, data, {
        headers: _authHeader(),
      });
    } else {
      return axios.post(API_URL + "deal-emails", data, { headers: _authHeader() });
    }
  }
  destroyEmail(id) {
    return axios.delete(API_URL + "deal-emails/" + id, { headers: _authHeader() });
  };
  sendBlogEmail(payload) {
    return axios.post(API_URL + "blog/blog-emails", payload, { headers: _authHeader() });

  }
  allEmails() {
    return axios.get(API_URL + "blog/available-emails", { headers: _authHeader() });

  }

  //end: deal
  //END: Settings

  // START: ticketing
  saveTicket(data) {

    // data.schedule_start_date = "2024-11-14 13:13:22";
    // data.due_date = "2024-11-14 13:13:22";
    console.log("dddddddddddddddddddate", data);
    if (data.id) {
      return axios.patch(API_URL + "help_desk/t_ticket/" + data.id, data, {
        headers: _authHeader(),
      });
    } else {
      return axios.post(API_URL + "help_desk/t_ticket", data, {
        headers: _authHeader(),
      });
    }
  }
  deleteTicket(id) {
    return axios.delete(API_URL + "help_desk/t_ticket/" + id, { headers: _authHeader() });
  }
  saveResponse(data) {
    if (data.id) {
      return axios.patch(API_URL + "help_desk/t_response/" + data.id, data, {
        headers: _authHeader(),
      });
    } else {
      return axios.post(API_URL + "help_desk/t_response", data, {
        headers: _authHeader(),
      });
    }
  }
  deleteResponse(id) {
    return axios.delete(API_URL + "help_desk/t_response/" + id, { headers: _authHeader() });
  }
  saveResponseReply(data) {
    if (data.id) {
      return axios.patch(API_URL + "help_desk/t_reply/" + data.id, data, {
        headers: _authHeader(),
      });
    } else {
      return axios.post(API_URL + "help_desk/t_reply", data, {
        headers: _authHeader(),
      });
    }
  }
  deleteResponseReply(id) {
    return axios.delete(API_URL + "help_desk/t_reply/" + id, { headers: _authHeader() });
  }
  clearNotification(data) {
    return axios.post(API_URL + "help_desk/clear-response-notification", data, { headers: _authHeader() });
  }  
  // END: ticketing
  //END: NEW---------------->
}


export default new apiService();
