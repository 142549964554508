import helpers from "@/helpers/helper";
import Axios from "./Axios";
import axios from 'axios'

const API_URL = helpers.API_ENDPOINT;

class clientService {
  getClient(data) { 
    return Axios({
      method  : "POST",
      url     : API_URL + "company-list" + '?page=' + data.page + '&size=' + data.size,
      data    : data,
    });
  }
  getPipeline(data) { 
    return Axios({
      method  : "POST",
      url     : API_URL + "pipelines" + '?page=' + data.page + '&size=' + data.size,
      data    : data,
    });
  }
  getStages()
  {
    return Axios({
      method  : "GET",
      url     : API_URL + "stages",
    });
  }
  getDeal(id) { 
    return Axios({
      method  : "GET",
      url     : API_URL + "company/" + id,
    });
  }
  postClient(data) {
    return Axios({
      method  : "POST",
      url     : API_URL + "company",
      data    : data,
    });
  }
  patchClient(data) {
    return Axios({
      method  : "PATCH",
      url     : API_URL + "company/" + data.id,
      data    : data,
    });
  }
  deleteDeal(data) { 
    return Axios({
      method  : "DELETE",
      url     : API_URL + "company/" + data.id,
    });
  }
  downloadExcel(data) {
    let user = JSON.parse(localStorage.getItem("_user"))
    if(user) {
      return axios.post(`${API_URL}download-company-list-excel`, data, {
        headers: { Authorization: "Bearer " + user.attributes.token, timeout: 1000},
        responseType: "arraybuffer",
      })
    }
  }
}
  
  export default new clientService();