const initialState = { show: false }

export const ual = {
    namespaced: true,

    state: initialState,

    actions: {
        alert_show({ commit }, logics) {
            commit("alert_show", logics);
        },
        module_name({ commit }, routeName) {
            commit("module_name", routeName);
        },
    },

    mutations: {
        alert_show(state, logics) {
            state.alert_show = logics;
        },
        module_name(state, routeName) {
            state.module_name = routeName;
        },

    },

}