import activityService from "@/services/activity.service";

export const activity = {
    namespaced: true,
    state: {},
    actions: {
        getCategory({ commit }, data) {
            return activityService.getCategory(data).then((data) => {
                commit("categories", data);
                return Promise.resolve(data);
            });
        },
        getBlog({ commit }, data) {
            return activityService.getBlog(data).then((data) => {
                commit("blogs", data);
                return Promise.resolve(data);
            });
        },
    },
    mutations: {
        categories(state, data) {
            state.categories = data;
        },
        blogs(state, data) {
            state.blogs = data;
        },
    },
}
