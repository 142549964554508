<template>
  <div class="main-wrapper">
    <div class="header header-one">
      <div class="header-left header-left-one" style="padding-right: 25px">
        <router-link to="/market/deals" class="logo">
          <img src="/assets/img/logo.png" alt="Logo" />
        </router-link>
        <router-link to="/market/deals" class="white-logo">
          <img src="/assets/img/logo.png" alt="Logo" />
        </router-link>
        <router-link to="/market/deals" class="logo logo-small">
          <img src="/assets/img/logo.png" alt="" width="30" height="30" />
        </router-link>
      </div>

      <a href="#" @click="draw" id="toggle_btn" style="padding-left: 25px">
        <i class="fas fa-bars"></i>
      </a>
      <a class="mobile_btn" id="mobile_btn">
        <i class="fas fa-bars"></i>
      </a>
      <a href="#" @click="draw" id="toggle_btn" style="padding-left: 25px">
        <span style="font-size: 15px" class="day-message"></span>
        <span style="font-size: 15px">,</span>
        <span style="font-size: 15px; margin-left: 10px"
          >{{ $helpers.greetings(this.user.attributes.name) }}!
          <!-- {{this.$store.state.ticketAnalytics?.notification}} -->
          <!-- {{notification}} -->
        </span>
      </a>
      <ul class="nav nav-tabs user-menu">
        <li>
          <a
            href="#"
            id="toggle_btn"
            style="padding-right: 10px"
            v-if="notification?.analytics?.total_notification > 0"
          >
            <el-badge
              :value="notification?.analytics?.total_notification"
              :max="99"
              style="margin-top: 40px; margin-right: 10px"
            >
              <el-dropdown>
                <i
                  :class="
                    notification?.analytics?.total_notification > 0
                      ? 'text-primary fas fa-bell el-icon--right'
                      : 'text-normal fas fa-bell el-icon--right'
                  "
                ></i>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item
                      @click="this.$router.push('/help-desk/tickets')"
                      class="clearfix"
                    >
                      Comments
                      <el-badge
                        class="mark"
                        :value="notification?.analytics?.notifications"
                      />
                    </el-dropdown-item>
                    <el-dropdown-item
                      @click="this.$router.push('/help-desk/tickets')"
                      class="clearfix"
                    >
                      Replies
                      <el-badge
                        class="mark"
                        :value="notification?.analytics?.replies"
                      />
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </el-badge>
          </a>
          <a href="#" id="toggle_btn" style="padding-right: 10px" v-else>
            <i :class="'text-normal fas fa-bell el-icon--right'"></i>
          </a>
        </li>
        <!-- ---------------- -->
        <li class="nav-item dropdown has-arrow main-drop">
          <a
            href="#"
            class="dropdown-toggle nav-link"
            data-bs-toggle="dropdown"
          >
            <span
              class="user-img"
              v-if="user.attributes.profile_photo != undefined"
            >
              <img :src="user.attributes.profile_photo" alt="" />
              <span class="status online"></span>
            </span>
            <el-avatar v-else class="me-1">
              {{ $helpers.getInitials(user.attributes.name) }}
            </el-avatar>
            <span>{{ user.attributes.name.split("")[0] }}</span>
          </a>
          <div class="dropdown-menu">
            <a class="dropdown-item" href="#" @click.prevent="Logout"
              ><i data-feather="log-out" class="me-1"></i> Sign Out</a
            >
          </div>
        </li>
      </ul>
    </div>

    <!-- start: side menu -->
    <div class="sidebar" id="sidebar">
      <!-- {{this.$store.state.auth.user.system_models}} -->
      <div class="sidebar-inner slimscroll">
        <div id="sidebar-menu" class="sidebar-menu">
          <ul>
            <li
              class="menu-title"
              v-if="
                this.$store.state.auth.user.system_models
                  .map((ab) => ab.slug)
                  .some((role) => ['dashboard', 'deal', 'task'].includes(role))
              "
            >
              <span>CRM</span>
            </li>
            <li
              :class="isActive == 'Dashboard' ? 'active' : ''"
              @click="setClass('Dashboard')"
              v-if="
                this.$store.state.auth.user.system_models
                  .map((ab) => ab.slug)
                  .some((role) => ['dashboard'].includes(role))
              "
            >
              <router-link to="/icp-dashboard" ref="ref1"
                ><i data-feather="home"></i> <span>Dashboard</span></router-link
              >
            </li>

            <li
              :class="isActive == 'Deals' ? 'active' : ''"
              @click="setClass('Deals')"
              v-if="
                this.$store.state.auth.user.system_models
                  .map((ab) => ab.slug)
                  .some((role) => ['deal'].includes(role))
              "
            >
              <router-link to="/market/deals" ref="ref2"
                ><i data-feather="menu"></i> <span>Deals</span></router-link
              >
            </li>

            <li
              :class="isActive == 'Tasks' ? 'active' : ''"
              @click="setClass('Tasks')"
              v-if="
                this.$store.state.auth.user.system_models
                  .map((ab) => ab.slug)
                  .some((role) => ['task'].includes(role))
              "
            >
              <router-link to="/task/tasks" ref="ref3"
                ><i data-feather="list"></i> <span>Tasks</span></router-link
              >
            </li>

            <li
              class="menu-title"
              v-if="
                this.$store.state.auth.user.system_models
                  .map((ab) => ab.slug)
                  .some((role) => ['ticket'].includes(role))
              "
            >
              <span>Tickets</span>
            </li>

            <li
              :class="isActive == 'Ticket' ? 'active' : ''"
              @click="setClass('Ticket')"
              v-if="
                this.$store.state.auth.user.system_models
                  .map((ab) => ab.slug)
                  .some((role) => ['ticket'].includes(role))
              "
            >
              <router-link to="/ticket/ticket"
                ><i data-feather="layers"></i> <span>Tickets</span></router-link
              >
            </li>

            <!-- start: affiliates -->
            <!-- <li
              class="menu-title"
              v-if="
                this.$store.state.auth.user.system_models
                  .map((ab) => ab.slug)
                  .some((role) => ['affiliates'].includes(role))
              "
            >
              <span>Affiliate</span>
            </li>

            <li
              :class="isActive == 'Marketers' ? 'active' : ''"
              @click="setClass('Marketers')"
              v-if="
                this.$store.state.auth.user.system_models
                  .map((ab) => ab.slug)
                  .some((role) => ['affiliates'].includes(role))
              "
            >
              <router-link to="/affiliates"
                ><i data-feather="users"></i>
                <span>Marketers</span></router-link
              >
            </li>
            <li
              :class="isActive == 'Business' ? 'active' : ''"
              @click="setClass('Business')"
              v-if="
                this.$store.state.auth.user.system_models
                  .map((ab) => ab.slug)
                  .some((role) => ['affiliates'].includes(role))
              "
            >
              <router-link to="/affiliate-deals"
                ><i data-feather="list"></i> <span>Business</span></router-link
              >
            </li> -->
            <!-- end: affiliates -->

            <!-- START: TICKETING -->
            <li
              class="menu-title"
              v-if="
                this.$store.state.auth.user.system_models
                  .map((ab) => ab.slug)
                  .some((role) =>
                    ['tickets', 'tickets_category'].includes(role)
                  )
              "
            >
              <span>HELP DESK</span>
            </li>
            <!-- Start: ticketing -->
            <li
              :class="
                isActive == 'Performance Insights'
                  ? 'submenu active'
                  : 'submenu'
              "
              @click="setClass('Performance Insights control')"
              v-if="
                this.$store.state.auth.user.system_models
                  .map((ab) => ab.slug)
                  .some((role) =>
                    ['tickets', 'tickets_category'].includes(role)
                  )
              "
            >
              <a href="#"
                ><i data-feather="inbox"></i>
                <span>Ticketing</span>
                <span class="menu-arrow"></span
              ></a>
              <ul>
                <li
                  v-if="
                    this.$store.state.auth.user.system_models
                      .map((ab) => ab.slug)
                      .some((role) => ['tickets'].includes(role))
                  "
                >
                  <router-link
                    to="/help-desk/tickets"
                    :class="isSubmenuActive == 'Tickets' ? 'active' : ''"
                    @click="setSubmenuClass('Tickets')"
                    >Tickets</router-link
                  >
                </li>
                <!-- <li
                  v-if="
                    this.$store.state.auth.user.system_models
                      .map((ab) => ab.slug)
                      .some((role) => ['tickets_category'].includes(role))
                  "
                >
                  <router-link
                    to="/help-desk/ticket-category"
                    :class="isSubmenuActive == 'Category' ? 'active' : ''"
                    @click="setSubmenuClass('Category')"
                    >Category</router-link
                  >
                </li> -->
              </ul>
            </li>
            <!-- end: ticketing -->

            <!-- START: WEBSITE -->
            <li
              class="menu-title"
              v-if="
                this.$store.state.auth.user.system_models
                  .map((ab) => ab.slug)
                  .some((role) =>
                    [
                      'interested',
                      'contact_us',
                      'facebook',
                      'partners',
                      'referral_client',
                      'partners_client',
                      'post',
                      'category',
                      'testimonial',
                    ].includes(role)
                  )
              "
            >
              <span>WEBSITE</span>
            </li>
            <!-- Start: website -->
            <li
              :class="
                isActive == 'Performance Insights'
                  ? 'submenu active'
                  : 'submenu'
              "
              @click="setClass('Performance Insights control')"
              v-if="
                this.$store.state.auth.user.system_models
                  .map((ab) => ab.slug)
                  .some((role) =>
                    [
                      'interested',
                      'contact_us',
                      'facebook',
                      'demo_request',
                    ].includes(role)
                  )
              "
            >
              <a href="#"
                ><i data-feather="inbox"></i>
                <span>Visitors</span>
                <span class="menu-arrow"></span
              ></a>
              <ul>
                <li
                  v-if="
                    this.$store.state.auth.user.system_models
                      .map((ab) => ab.slug)
                      .some((role) => ['interested'].includes(role))
                  "
                >
                  <router-link
                    to="/interested"
                    :class="isSubmenuActive == 'Interested' ? 'active' : ''"
                    @click="setSubmenuClass('Interested')"
                    >Interested</router-link
                  >
                </li>
                <li
                  v-if="
                    this.$store.state.auth.user.system_models
                      .map((ab) => ab.slug)
                      .some((role) => ['contact_us'].includes(role))
                  "
                >
                  <router-link
                    to="/contact-us"
                    :class="isSubmenuActive == 'Contact Us' ? 'active' : ''"
                    @click="setSubmenuClass('Contact Us')"
                    >Contact Us</router-link
                  >
                </li>
                <li
                  v-if="
                    this.$store.state.auth.user.system_models
                      .map((ab) => ab.slug)
                      .some((role) => ['demo_request'].includes(role))
                  "
                >
                  <router-link
                    to="/demo-request"
                    :class="isSubmenuActive == 'Demo Request' ? 'active' : ''"
                    @click="setSubmenuClass('Demo Request')"
                    >Demo Request</router-link
                  >
                </li>
                <li
                  v-if="
                    this.$store.state.auth.user.system_models
                      .map((ab) => ab.slug)
                      .some((role) => ['facebook'].includes(role))
                  "
                >
                  <router-link
                    to="/facebook"
                    :class="isSubmenuActive == 'Facebook' ? 'active' : ''"
                    @click="setSubmenuClass('Facebook')"
                    >Facebook</router-link
                  >
                </li>
              </ul>
            </li>
            <!-- end: website -->

            <!-- start: partmers -->
            <li
              :class="isActive == 'Partners' ? 'submenu active' : 'submenu'"
              @click="setClass('Partners cntrol')"
              v-if="
                this.$store.state.auth.user.system_models
                  .map((ab) => ab.slug)
                  .some((role) =>
                    ['partners', 'referral_client', 'partners_client'].includes(
                      role
                    )
                  )
              "
            >
              <a href="#"
                ><i data-feather="folder"></i>
                <span>Partners</span>
                <span class="menu-arrow"></span
              ></a>
              <ul>
                <li
                  v-if="
                    this.$store.state.auth.user.system_models
                      .map((ab) => ab.slug)
                      .some((role) => ['partners'].includes(role))
                  "
                >
                  <router-link
                    to="/partner/profiles"
                    :class="
                      isSubmenuActive == 'Partner Profiles' ? 'active' : ''
                    "
                    @click="setSubmenuClass('Partner Profiles')"
                    >Partners</router-link
                  >
                </li>
                <li
                  v-if="
                    this.$store.state.auth.user.system_models
                      .map((ab) => ab.slug)
                      .some((role) => ['referral_client'].includes(role))
                  "
                >
                  <router-link
                    to="/partners-referrals"
                    :class="isSubmenuActive == 'Referrals' ? 'active' : ''"
                    @click="setSubmenuClass('Referrals')"
                    >Referral Clients</router-link
                  >
                </li>
                <li
                  v-if="
                    this.$store.state.auth.user.system_models
                      .map((ab) => ab.slug)
                      .some((role) => ['partners_client'].includes(role))
                  "
                >
                  <router-link
                    to="/partners-clients"
                    :class="isSubmenuActive == 'Contacts' ? 'active' : ''"
                    @click="setSubmenuClass('Contacts')"
                    >Partner Clients</router-link
                  >
                </li>
              </ul>
            </li>
            <!-- end: partmers -->
            <!-- start: blogs -->
            <li
              :class="
                isActive == 'Performance Insights'
                  ? 'submenu active'
                  : 'submenu'
              "
              @click="setClass('Performance Insights control')"
              v-if="
                this.$store.state.auth.user.system_models
                  .map((ab) => ab.slug)
                  .some((role) => ['post', 'category'].includes(role))
              "
            >
              <a href="#"
                ><i data-feather="list"></i>
                <span>Blogs</span>
                <span class="menu-arrow"></span
              ></a>
              <ul>
                <li
                  v-if="
                    this.$store.state.auth.user.system_models
                      .map((ab) => ab.slug)
                      .some((role) => ['post'].includes(role))
                  "
                >
                  <router-link
                    to="/blog/manage/blog"
                    :class="isSubmenuActive == 'Post' ? 'active' : ''"
                    @click="setSubmenuClass('Post')"
                    >Post</router-link
                  >
                </li>
                <li
                  v-if="
                    this.$store.state.auth.user.system_models
                      .map((ab) => ab.slug)
                      .some((role) => ['category'].includes(role))
                  "
                >
                  <router-link
                    to="/blog/category"
                    :class="isSubmenuActive == 'Category' ? 'active' : ''"
                    @click="setSubmenuClass('Category')"
                    >Category</router-link
                  >
                </li>
              </ul>
            </li>
            <!-- end: blogs -->
            <!-- start: testmonials -->
            <li
              :class="isActive == 'Testimonials' ? 'active' : ''"
              @click="setClass('Testimonials')"
              v-if="
                this.$store.state.auth.user.system_models
                  .map((ab) => ab.slug)
                  .some((role) => ['testimonial'].includes(role))
              "
            >
              <router-link to="/testimonials"
                ><i data-feather="edit"></i>
                <span>Testimonials</span></router-link
              >
            </li>
            <!-- end: testmonials -->
            <!-- END: WEBSITE -->

            <!-- START: SALES -->
            <!-- start: sales -->
            <li
              class="menu-title"
              v-if="
                this.$store.state.auth.user.system_models
                  .map((ab) => ab.slug)
                  .some((role) =>
                    [
                      'sales',
                      'account_value',
                      'sales_projection',
                      'email_metrics',
                    ].includes(role)
                  )
              "
            >
              <span>SALES</span>
            </li>
            <li
              :class="
                isActive == 'Performance Insights'
                  ? 'submenu active'
                  : 'submenu'
              "
              @click="setClass('Performance Insights control')"
              v-if="
                this.$store.state.auth.user.system_models
                  .map((ab) => ab.slug)
                  .some((role) =>
                    [
                      'sales',
                      'account_value',
                      'sales_projection',
                      'email_metrics',
                    ].includes(role)
                  )
              "
            >
              <a href="#"
                ><i data-feather="calendar"></i>
                <span>Performance Insights</span>
                <span class="menu-arrow"></span
              ></a>
              <ul>
                <li>
                  <router-link
                    to="/sales/sales"
                    :class="isSubmenuActive == 'Sales' ? 'active' : ''"
                    @click="setSubmenuClass('Sales')"
                    v-if="
                      this.$store.state.auth.user.system_models
                        .map((ab) => ab.slug)
                        .some((role) => ['sales'].includes(role))
                    "
                    >Sales</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/sales/account_values"
                    :class="isSubmenuActive == 'Account Value' ? 'active' : ''"
                    @click="setSubmenuClass('Account Value')"
                    v-if="
                      this.$store.state.auth.user.system_models
                        .map((ab) => ab.slug)
                        .some((role) => ['account_value'].includes(role))
                    "
                    >Account Value</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/sales/sales_projection"
                    :class="
                      isSubmenuActive == 'Sales Projection' ? 'active' : ''
                    "
                    @click="setSubmenuClass('Sales Projection')"
                    v-if="
                      this.$store.state.auth.user.system_models
                        .map((ab) => ab.slug)
                        .some((role) => ['sales_projection'].includes(role))
                    "
                    >Projection</router-link
                  >
                </li>
                <li
                  v-if="
                    this.$store.state.auth.user.system_models
                      .map((ab) => ab.slug)
                      .some((role) => ['email_metrics'].includes(role))
                  "
                >
                  <router-link
                    to="/sales/email_metrics"
                    :class="isSubmenuActive == 'Email Metrix' ? 'active' : ''"
                    @click="setSubmenuClass('Email Metrix')"
                    >Digital Metrics</router-link
                  >
                </li>
              </ul>
            </li>
            <!-- end: sales -->
            <!-- END: SALES -->
            <li
              class="menu-title"
              v-if="
                this.$store.state.auth.user.system_models
                  .map((ab) => ab.slug)
                  .some((role) =>
                    ['users', 'roles_and_permissions', 'api_keys'].includes(
                      role
                    )
                  )
              "
            >
              <span>SETTINGS</span>
            </li>
            <li
              :class="
                isActive == 'Performance Insights'
                  ? 'submenu active'
                  : 'submenu'
              "
              @click="setClass('Performance Insights control')"
              v-if="
                this.$store.state.auth.user.system_models
                  .map((ab) => ab.slug)
                  .some((role) =>
                    ['users', 'roles_and_permissions'].includes(role)
                  )
              "
            >
              <a href="#"
                ><i data-feather="list"></i>
                <span>Security</span>
                <span class="menu-arrow"></span
              ></a>
              <ul>
                <li
                  v-if="
                    this.$store.state.auth.user.system_models
                      .map((ab) => ab.slug)
                      .some((role) => ['users'].includes(role))
                  "
                >
                  <router-link
                    to="/settings/users"
                    :class="isSubmenuActive == 'Post' ? 'active' : ''"
                    @click="setSubmenuClass('Post')"
                    >Users</router-link
                  >
                </li>
                <li
                  v-if="
                    this.$store.state.auth.user.system_models
                      .map((ab) => ab.slug)
                      .some((role) => ['roles_and_permissions'].includes(role))
                  "
                >
                  <router-link
                    to="/settings/roles_and_permission"
                    :class="isSubmenuActive == 'Category' ? 'active' : ''"
                    @click="setSubmenuClass('Category')"
                    >Roles & permission</router-link
                  >
                </li>
              </ul>
            </li>
            <!-- start: alternative -->
            <!-- <li
              :class="
                isActive == 'Performance Insights'
                  ? 'submenu active'
                  : 'submenu'
              "
              @click="setClass('Performance Insights control')"
            >
              <a href="#"
                ><i data-feather="list"></i>
                <span>Security</span>
                <span class="menu-arrow"></span
              ></a>
              <ul>
                <li>
                  <router-link
                    to="/settings/users"
                    :class="isSubmenuActive == 'Post' ? 'active' : ''"
                    @click="setSubmenuClass('Post')"
                    >Users</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/settings/roles_and_permission"
                    :class="isSubmenuActive == 'Category' ? 'active' : ''"
                    @click="setSubmenuClass('Category')"
                    >Roles & permission</router-link
                  >
                </li>
              </ul>
            </li> -->
            <!-- end: alternative -->
            <li
              :class="isActive == 'Tasks' ? 'active' : ''"
              @click="setClass('Tasks')"
              v-if="
                this.$store.state.auth.user.system_models
                  .map((ab) => ab.slug)
                  .some((role) => ['api_keys'].includes(role))
              "
            >
              <router-link to="/settings/api_keys"
                ><i data-feather="list"></i> <span>APIKeys</span></router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- end: side menu -->
    <!-- start: end alert -->
    <div class="page-wrapper">
      <div class="content container-fluid">
        <el-alert
          v-if="alert"
          :title="`You're NOT authorized to access | ${route_name}!`"
          type="error"
        />
        <el-skeleton v-if="loading" :rows="15" animated />
        <router-view v-else></router-view>
      </div>
    </div>
    <!-- end: end alert -->
    <!-- start: warning -->
    <el-dialog
      v-model="warningZone"
      title="Session Timeout"
      width="30%"
      :before-close="handleClose"
    >
      <p>
        You have been inactive for the passed 14 minutes! Your session expires
        in a minutes, if no action taken.
      </p>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="danger" plain @click="Logout">Sign Out</el-button>
          <el-button type="primary" plain @click="this.warningZone = false"
            >Continue</el-button
          >
        </span>
      </template>
    </el-dialog>
    <!-- end: warning -->
  </div>
  <el-tour v-model="open" @change="handleStepChange">
    <el-tour-step :target="ref1?.$el" placement="right">
      <div class="title">Manage your reports</div>

      <div>
        On this panel, you will be able to organize all your deals. You can also
        filter them by date.
      </div>
    </el-tour-step>
    <el-tour-step :target="ref2?.$el" placement="right">
      <div class="title">Deal Management</div>

      <div>
        Access a list of all your deals right here. You can also edit or delete
        them if needed.
      </div>
    </el-tour-step>
    <el-tour-step :target="ref3?.$el" placement="right">
      <div class="title">Almost there! - Add Tasks</div>

      <div>
        Tasks result from deals created earlier. You can schedule a task,
        meeting, call, or send an email.
      </div>
    </el-tour-step>
  </el-tour>
</template>

<script>
import { ref, watch } from "vue";
import { useRouter } from "vue-router";
import { MoreFilled } from "@element-plus/icons-vue";

export default {
  components: {
    MoreFilled,
  },
  setup() {
    const open = ref(false);
    const ref1 = ref(null);
    const ref2 = ref(null);
    const ref3 = ref(null);
    const router = useRouter();
    const tourStepsRoutes = ["/icp-dashboard", "/market/deals", "/task/tasks"];

    const handleStepChange = (currentStep) => {
      if (tourStepsRoutes[currentStep]) {
        router.push(tourStepsRoutes[currentStep]);
      }
    };
    return {
      open,
      ref1,
      ref2,
      ref3,
      handleStepChange,
    };
  },
  data() {
    return {
      data: "",
      loading: false,
      isActive: "Dashboard",
      isSubmenuActive: null,
      filteredCompany: [],
      events: ["click", "mousedown", "scroll", "keypress", "load"],
      warningTimer: null,
      logoutTimer: null,
      warningZone: false,
      dialogVisible: false,
      activeMenuItem: null,
      visible: null,
      newUser: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.auth.user ? this.$store.state.auth.user : {};
    },
    isNewUser() {
      return this.user.guides.logins.logins === 1;
    },
    alert() {
      if (this.$store.state.ual.alert_show != undefined) {
        return this.$store.state.ual.alert_show.show;
      }
    },
    route_name() {
      if (this.$store.state.ual.module_name != undefined) {
        return this.$store.state.ual.module_name.requestedRouteName;
      }
    },
    notification() {
      this.tLoading = true;
      if (this.$store.state.ticketAnalytics.notification != undefined) {
        this.tLoading = false;
        return this.$store.state.ticketAnalytics.notification;
      } else {
        return null;
      }
    },
  },
  created() {
    this.getNotification();
    this.notificationInterval = setInterval(this.getNotification, 10000);
    var scripts = ["/assets/js/script.js"];
    scripts.forEach((script) => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.head.appendChild(tag);
    });
    Number.prototype.leadingZeroes = function (len) {
      return (new Array(len).fill("0", 0).join("") + this).slice(
        -Math.abs(len)
      );
    };
    this.greetings();
    this.$router.beforeEach((to, from, next) => {
      this.isPopoverVisible = false;
      next();
    });
  },
  mounted() {
    this.events.forEach(function (event) {
      window.addEventListener(event, this.resetTimer);
    }, this);
    this.setTimers();
    const isOpenInLocalStorage = localStorage.getItem("open") === "true";

    if (
      this.user &&
      this.user.guides.logins.logins === 1 &&
      !isOpenInLocalStorage
    ) {
      this.open = true;
      localStorage.setItem("open", "true");
    } else {
      this.open = false;
    }
  },
  beforDestroy() {
    clearInterval(this.notificationInterval);
  },
  destroyed() {
    this.events.forEach(function (event) {
      window.removeEventListener(event, this.resetTimer);
    }, this);
    this.resetTimer();
  },
  methods: {
    setClass(active) {
      this.isActive = active;
    },
    setSubmenuClass(active) {
      this.isSubmenuActive = active;
    },
    Logout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/signin");
      window.location.reload();
    },
    setTimers() {
      localStorage.setItem("_warningtime", 14 * 60 * 1000);
      const warningTimer = parseInt(localStorage.getItem("_warningtime"));
      this.warningTimer = setTimeout(this.warningMessage, warningTimer);
      localStorage.setItem("_logouttime", 15 * 60 * 1000);
      const logoutTimer = parseInt(localStorage.getItem("_logouttime"));
      this.logoutTimer = setTimeout(this.Logout, logoutTimer);
    },
    warningMessage() {
      this.warningZone = true;
    },
    resetTimer() {
      clearTimeout(this.warningTimer);
      clearTimeout(this.logoutTimer);
      this.setTimers();
    },
    draw() {
      if ($("body").hasClass("mini-sidebar")) {
        $("body").removeClass("mini-sidebar");
        $(".subdrop + ul").slideDown();
      } else {
        $("body").addClass("mini-sidebar");
        $(".subdrop + ul").slideUp();
      }
      setTimeout(function () {}, 300);
      return false;
    },
    greetings() {
      $(document).ready(function () {
        function dateTime() {
          var ndate = new Date();
          var hours = ndate.getHours();
          var message =
            hours < 12
              ? "Good Morning"
              : hours < 18
              ? "Good Afternoon"
              : "Good Evening";
          $("span.day-message").text(message);
        }
        setInterval(dateTime, 1000);
      });
    },
    getNotification() {
      this.$store.dispatch("ticketAnalytics/getNotification");
    },
    handleClose() {
      this.warningZone = false;
    },
  },
};
</script>
<style>
.title {
  color: #29abe2;
  font-weight: bold;
  font-size: 1.5em;
  margin-bottom: 5px;
}
</style>