<template>
  <div>
    <el-card>
      <body class="body">
        <h3 class="h3" style="color: #05b7fd">
          Account Recovery Steps for Users and Admins
        </h3>
        <hr />
        <p>
          Follow these steps to regain access and address verification code
          issues.
        </p>
        <ol>
          <li class="li">
            <span class="important">Verify Email Address and Spam Folder</span>
            <ul class="ul">
              <li class="li">
                Double-check that you are using the correct email address.
              </li>
              <li class="li">
                Check your spam or junk folder to see if the email with the code
                was mistakenly filtered.
              </li>
            </ul>
          </li>
          <li class="li">
            <span class="important">Resend Verification Code</span>
            <ul class="ul">
              <li class="li">
                Look for an option to resend the verification code on the login
                or verification page.
              </li>
              <li class="li">
                Ensure that you wait a few minutes between attempts, as there
                might be a delay in receiving the code.
              </li>
            </ul>
          </li>
          <li class="li">
            <span class="important">Use Admin Console or Control Panel</span>
            <ul class="ul">
              <li class="li">
                Access the admin console or control panel to verify user account
                status and troubleshoot issues.
              </li>
              <li class="li">
                Check the email delivery logs to see if the verification email
                was sent and delivered.
              </li>
              <li class="li">
                Resend the verification email directly from the admin console if
                possible.
              </li>
            </ul>
          </li>
          <li class="li">
            <span class="important">Check System Status</span>
            <ul class="ul">
              <li class="li">
                Verify if there are any ongoing system maintenance or outages
                that could be affecting email delivery or code generation.
              </li>
              <li class="li">
                This information is usually available on your organization's IT
                status page or communicated via other channels.
              </li>
            </ul>
          </li>
          <li class="li">
            <span class="important">Review Account Security Settings</span>
            <ul class="ul">
              <li class="li">
                Ensure that all recommended security measures, such as
                two-factor authentication (2FA) and recovery options, are set
                up.
              </li>
              <li class="li">
                Regularly review and update your security settings to prevent
                access issues.
              </li>
            </ul>
          </li>
          <li class="li">
            <span class="important">Update Contact Information</span>
            <ul class="ul">
              <li class="li">
                Ensure that your contact information, including your phone
                number and secondary email address, is up-to-date.
              </li>
              <li class="li">
                This will help in future recovery processes and prevent similar
                issues.
              </li>
            </ul>
          </li>
          <li class="li">
            <span class="important">Alternative Verification Methods</span>
            <ul class="ul">
              <li class="li">
                Utilize alternative methods for verification if available, such
                as:
              </li>
              <ul class="ul">
                <li class="li">SMS verification</li>
                <li class="li">
                  Authenticator apps (e.g., Google Authenticator, Authy)
                </li>
                <li class="li">Backup codes provided during initial setup</li>
              </ul>
            </ul>
          </li>
          <li class="li">
            <span class="important">Contact IT Support</span>
            <ul class="ul">
              <li class="li">
                Reach out to Zemo Card organization's IT support or help-desk
                for assistance.
              </li>
              <li class="li">
                Provide them with your user details and explain the issue you
                are facing.
              </li>
              <li class="li">
                Request manual intervention if necessary, such as resetting your
                email address or phone number.
              </li>
            </ul>
          </li>
          <li class="li">
            <span class="important">Clear Browser Cache and Cookies</span>
            <ul class="ul">
              <li class="li">
                Sometimes, clearing your browser’s cache and cookies can resolve
                issues with receiving codes or accessing accounts.
              </li>
              <li class="li">
                Try logging in from a different browser or device to see if the
                issue persists.
              </li>
            </ul>
          </li>
          <li class="li">
            <span class="important">Escalation Procedures</span>
            <ul class="ul">
              <li class="li">
                <span class="important">Super Admins:</span> Coordinate with
                other super admins to ensure that account recovery is handled
                securely and efficiently.
              </li>
              <li class="li">
                <span class="important">Admin Actions:</span>
                <ul class="ul">
                  <li class="li">
                    Use admin privileges to reset user passwords or update
                    contact information as needed.
                  </li>
                  <li class="li">
                    Access higher-level support if necessary, such as contacting
                    the service provider for assistance with account recovery.
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="li">
            <span class="important">Document the Process</span>
            <ul class="ul">
              <li class="li">
                Keep a record of the steps taken to resolve the issue, including
                any interactions with IT support or changes made in the admin
                console.
              </li>
              <li class="li">
                Documenting the process can help in troubleshooting similar
                issues in the future and provide a reference for other admins or
                super admins.
              </li>
            </ul>
          </li>
        </ol>
        <p>
          Ensure security measures and contact information are up-to-date to
          prevent future problems.
        </p>
      </body>
    </el-card>
    <!-- </div> -->
    <div class="text-center">
      <small>&copy; {{ $helpers.copyright }}</small
      ><br />
      <small>{{ $helpers.version }}</small>
    </div>
    <!-- </div> -->
  </div>
</template>

<style scope>
.body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  padding: 1px 550px 20px 550px;
  background-color: #ffffff;
  align-items: center;
}
.h3 {
  text-align: center;
}
.ul {
  list-style-type: none;
  padding: 0;
}
.li {
  margin-bottom: 10px;
}
.li .ul {
  margin-top: 5px;
  margin-left: 20px;
}
.li .ul .li {
  margin-bottom: 5px;
}
.important {
  font-weight: bold;
  color: #05b7fd;
}
.el-card {
  margin: 0.2rem;
}
</style>