import TasksService from "@/services/market/tasks.service";

export const task = {
    namespaced: true,

    state: {},

    actions: {
        getTasks({ commit }, data) {
            return TasksService.getTasks(data).then((data) => {
                commit("tasks", data);
                return Promise.resolve(data);
            });
        },
    },

    mutations: {
        tasks(state, data) {
            state.tasks = data;
        },
    }


    

}