import EmailsService from "@/services/market/emails.service";

export const email = {
    namespaced: true,

    state: {},

    actions: {
        getEmails({ commit }, data) {
            return EmailsService.getEmails(data).then((data) => {
                commit("emails", data);
                return Promise.resolve(data);
            });
        },
    },

    mutations: {
        emails(state, data) {
            state.emails = data;
        },
    }


    

}