import axios from "axios"
import helpers from "@/helpers/helper"
import Axios from "./Axios";

const API_URL = helpers.API_ENDPOINT;

class AuthService {
    signin(user) {
        return axios.post(API_URL + "auth/login", user).then((response) => {
            return response.data;
        });
    }
    verifyOtp(user) {
        return axios.post(API_URL + "auth/verify", user).then((response) => {
            if (response.data.data.attributes) {
                localStorage.setItem("_user", JSON.stringify(response.data.data));
            }
            return response.data.data;
        });
    }
    logout() {
        localStorage.removeItem("_user");
        // localStorage.removeItem("user");
        localStorage.removeItem("_filteredUser");
    }

    forgotPassword(data) {
        return Axios({
            method: 'POST',
            url: API_URL + 'auth/forgot-password',
            data: data
        })
    }
    resetPassword(data) {
        return Axios({
            method: 'POST',
            url: API_URL + 'auth/change-password',
            data: data
        })
    }

}

export default new AuthService;