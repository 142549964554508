import permissionService from "@/services/settings/permission.service";

export const permissions = {
    namespaced: true,

    state: {},

    actions: {
        getPermission({ commit }, data) {
            return permissionService.getPermission(data).then((data) => {
                commit("permissions", data);
                return Promise.resolve(data);
            });
        },
    },

    mutations: {
        permissions(state, data) {
            state.permissions = data;
        },
    }

}