import modelsService from "@/services/settings/models.service";

export const models = {
    namespaced: true,

    state: {},

    actions: {
        getModels({ commit }, data) {
            return modelsService.getModels(data).then((data) => {
                commit("models", data);
                return Promise.resolve(data);
            });
        },
        getModelSelect({ commit }, data) {
            return modelsService.getModelSelect(data).then((data) => {
                commit("system_model_select", data);
                return Promise.resolve(data);
            });
        },
    },

    mutations: {
        models(state, data) {
            state.models = data;
        },
        system_model_select(state, data) {
            state.system_model_select = data;
        }
    }

}
