import axios from "axios";
import { _authHeader } from "../auth._header";
import helpers from "@/helpers/helper";

const API_URL = helpers.API_ENDPOINT;

class TaskService {
    getTasks(data) {
        return axios.get(API_URL + 'deal-tasks/' + data.deal_id, { params: helpers.params(data), headers: _authHeader(), }).then((response) => {
            return response.data;
        });
    };
}

export default new TaskService();