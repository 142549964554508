import axios from "axios";
import { _authHeader } from "../auth._header";
import helpers from "@/helpers/helper";

const API_URL = helpers.API_ENDPOINT;

class StagesService {
    getStages() {
        return axios.get(API_URL + "stages", {headers: _authHeader(), }).then((response) => {
            return response.data;
        });
    };
}

export default new StagesService();