import helpers from '@/helpers/helper';
import Axios from './Axios';
import axios from 'axios';
import { _authHeader } from "./auth._header";

const API_URL = helpers.API_ENDPOINT;

class interestedService {
    // getInterested(data) {
    //   return Axios({
    //     method  : 'GET',
    //     url     : API_URL + 'blog/interested' + '?page=' + data.page,
    //     data    : data,
    //   });
    // }
    getInterested(data){
      return axios.get(API_URL + "blog/interested" + '?page=' + data.page, { headers: _authHeader() });
      
    }
    patchInterested(data) {
      return Axios({
        method  : 'PATCH',
        url     : API_URL + 'blog/interested/' + data.id,
        data    : data,
      });
    }
  }
export default new interestedService();