import helpers from '@/helpers/helper';
import Axios from './Axios';

const API_URL = helpers.API_ENDPOINT;

class affiliateService {
    getAllMarketers(data) {
      return Axios({
        method  : 'GET',
        url     : API_URL + 'affiliate/marketer',
      });
    }
    getMarketers(data) {
      return Axios({
        method  : 'POST',
        url     : API_URL + 'affiliate/marketers' + '?page=' + data.page,
        data    : data,
      });
    }
    postMarketers(data) { 
      return Axios({
        method  : 'POST',
        url     : API_URL + 'affiliate/marketer',
        data    : data,
      });
    }
    patchMarketers(data) { 
      return Axios({
        method  : 'PATCH',
        url     : API_URL + 'affiliate/marketer/' + data.id,
        data    : data,
      });
    }
    deleteMarketers(data) { 
      return Axios({
        method  : 'DELETE',
        url     : API_URL + 'affiliate/marketer/' + data.id,
      });
    }
    /**
     * 
     * 
     * 
     */
    getBusiness(data) {
      return Axios({
        method  : 'POST',
        url     : API_URL + 'affiliate/business' + '?page=' + data.page,
        data    : data,
      });
    }
  }
export default new affiliateService();