import teamService from "@/services/team.service";

export const team = {
    namespaced  : true,
    state       : {
        team    : [],
        meta    : {}
    },
    actions     : {
        getTeam({ commit }, data) {
            return teamService.getTeam(data).then((response) => {
                commit("team", response.data);
                return Promise.resolve(response);
            });
        },
        getAllTeam({commit}, data){
            return teamService.getAllTeam(data).then((response) =>{
                commit("all_ream_members", response.data);
                return Promise.resolve(response);
            })
        },
        postTeam({ commit }, data) {
            return teamService.postTeam(data).then((response) => {
                commit("user", response.data);
                return Promise.resolve(response);
            });
        },
        patchTeam({ commit }, data) {
            return teamService.patchTeam(data).then((response) => {
                commit("user", response.data);
                return Promise.resolve(response);
            });
        },
        deactivateTeam({ commit }, data) {
            return teamService.deleteTeam(data).then((response) => {
                commit("deactivate", response.data);
                return Promise.resolve(response);
            });
        },
    },
    mutations   : {
        team(state, data) {
            state.team = data.data;
            state.meta = data.meta;
        },
        all_ream_members(state, data){
            state.all_ream_members = data;            
        },
        user(state, data) {

        },
        deactivate(state, data) {

        }
    },
};