import TicketAnalyticsSerive from "@/services/helpDesk/ticketing/ticketAnalytics.serive";

export const ticketAnalytics = {
    namespaced: true,

    state: {},

    actions: {
        getNotification({ commit }, data) {
            return TicketAnalyticsSerive.getNotification(data).then((data) => {
                commit("notification", data);
                return Promise.resolve(data);
            });
        },
    },
    mutations: {
        notification(state, data) {
            state.notification = data;
        },
    }
}