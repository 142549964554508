import rolesService from "@/services/settings/roles.service";

export const roles = {
    namespaced: true,

    state: {},

    actions: {
        getRoles({ commit }, data) {
            return rolesService.getRoles(data).then((data) => {
                commit("system_roles", data);
                return Promise.resolve(data);
            });
        },
        getRolesSelect({ commit }, data) {
            return rolesService.getRolesSelect(data).then((data) => {
                commit("system_roles_select", data);
                return Promise.resolve(data);
            });
        },
    },

    mutations: {
        system_roles(state, data) {
            state.system_roles = data;
        },
        system_roles_select(state, data) {
            state.system_roles_select = data;
        }
    }

}