import { createStore } from 'vuex';
import { auth } from "./auth";
import { client } from './client';
// import { task } from './task';
import { team } from './team';
// import { notes } from './notes';
// import { email } from './email';
import { zemo } from './zemo';
import { prospects } from './prospects';
import { activity } from './activity.module';
import { affiliate } from './affiliate';
import { interested } from './interested';
import { contact_us } from './contactus';
import { sales } from './sales.module';
// import { ticket } from './ticket/ticket';
// import { ticket_notes } from './ticket/ticket_notes';
// import { ticket_status } from './ticket/ticket_status';
// import { ticket_priority } from './ticket/ticket_priority';
// import { ticket_category } from './ticket/ticket_category';
import { settings } from './settings.module';
// new checks
import { ual } from './ual.module';
import { user } from './organization/user.module';
import { roles } from './settings/roles.module';
import { permissions } from './settings/permissions.module';
import { models } from './settings/models.module';
import { deal } from './market/deals.module';
import { task } from './market/tasks.module';
import { note } from './market/notes.module';
import { email } from './market/emails.module';
import { stage } from './settings/stages.module';
import { analytics } from './analytics/analytics';
import { ticketing } from './helpDesk/ticketing/ticket.module';
import { ticketAnalytics } from './helpDesk/ticketing/ticketAnalytics.module';


const store = createStore({
  modules: {
    auth,
    client,
    team,
    // notes,
    zemo,
    prospects,
    activity,
    affiliate,
    interested,
    contact_us,
    sales,
    // ticket_notes,
    // ticket_status,
    // ticket_priority,
    // ticket_category,
    settings,
    //start: new checks
    ual,
    user,
    roles,
    permissions,
    models,
    deal,
    task,
    note,
    email,
    stage,
    analytics,
    ticketing,
    ticketAnalytics,
    //end: new checks
  }
})

export default store;
