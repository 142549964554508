<template>
  <div class="main-wrapper">
    <div class="header header-one">
      <div class="header-left header-left-one" style="padding-right: 25px">
        <router-link to="/signin" class="logo">
          <img src="/assets/img/logo.png" alt="Logo" />
        </router-link>
        <router-link to="/market/deals" class="white-logo">
          <img src="/assets/img/logo.png" alt="Logo" />
        </router-link>
        <router-link to="/market/deals" class="logo logo-small">
          <img src="/assets/img/logo.png" alt="" width="30" height="30" />
        </router-link>
      </div>
      <a href="#" @click="draw" id="toggle_btn" style="padding-left: 25px">
        <span style="font-size: 15px" class="day-message"></span>
        <span style="font-size: 15px">,</span>
        <span style="font-size: 15px; margin-left: 10px"> User </span>
      </a>

      <ul class="nav nav-tabs user-menu">
        <a href="#" class="dropdown-toggle nav-link" @click.prevent="signIn">
          <el-avatar class="me-1"> User </el-avatar>
          <span>SIign In</span>
        </a>
      </ul>
    </div>
      <div class="content container-fluid">
        <el-skeleton v-if="loading" :rows="15" animated />
        <router-view> <ACRecoverStepsVue /> </router-view>
      </div>
  </div>
</template>

<script>
import ACRecoverStepsVue from "./components/ACRecoverSteps.vue";
export default {
  components: {
    ACRecoverStepsVue,
  },
  data() {
    return {
      data: "",
      loading: false,
    };
  },
  created() {
    this.greetings();
  },

  methods: {
    signIn() {
      this.$router.push("signin");
    },
    greetings() {
      $(document).ready(function () {
        function dateTime() {
          var ndate = new Date();
          var hours = ndate.getHours();
          var message =
            hours < 12
              ? "Good Morning"
              : hours < 18
              ? "Good Afternoon"
              : "Good Evening";
          $("span.day-message").text(message);
        }
        setInterval(dateTime, 1000);
      });
    },
  },
};
</script>





























