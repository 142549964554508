import axios from "axios";
import { _authHeader } from "@/services/auth._header";
import helpers from "@/helpers/helper";

const API_URL = helpers.API_ENDPOINT;

class TicketAnalyticsService {
    getNotification(data) {
        return axios.get(API_URL + "help_desk/notification", {headers: _authHeader(), }).then((response) => {
            return response.data;
        });
    };

}

export default new TicketAnalyticsService();