import affiliateService from "@/services/affiliate.service";
import clientService from "@/services/client.service";

export const affiliate = {
    namespaced  : true,
    state       : {
        marketers   : [],
        business    : [],
        mkt_meta    : [],
        bus_meta    : []
    },
    actions : {
        /**
         * 
         * Affiliate marketers 
         * 
         */
        getMarketers({ commit }, data) {
            return affiliateService.getMarketers(data).then((response) => {
                commit("marketers", response.data);
                return Promise.resolve(response);
            });
        },
        postMarketer({ commit }, data) {
            return affiliateService.postMarketer(data).then((response) => {
                commit("marketer", response.data.data);
                return Promise.resolve(response);
            });
        },
        patchMarketer({ commit }, data) {
            return affiliateService.patchMarketer(data).then((response) => {
                commit("marketer", response.data.data);
                return Promise.resolve(response);
            });
        },
        deleteMarketer({ commit }, data) {
            return affiliateService.deleteMarketer(data).then((response) => {
                commit("delete_mkt", data);
                return Promise.resolve(response);
            });
        },
        /**
         * 
         * Bussiness brought by affiliate marketers
         * 
         */
        getBusiness({ commit }, data) {
            return affiliateService.getBusiness(data).then((response) => {
                commit("businesses", response.data);
                return Promise.resolve(response);
            });
        },
        postBusiness({ commit }, data) {
            return clientService.postClient(data).then((response) => {
                commit("business", response.data.data);
                return Promise.resolve(response);
            });
        },
        patchBusiness({ commit }, data) {
            return clientService.patchClient(data).then((response) => {
                commit("business", response.data.data);
                return Promise.resolve(response);
            });
        },
        deleteBusiness({ commit }, data) {
            return clientService.deleteDeal(data.id).then((response) => {
                commit("delete_bus", data);
                return Promise.resolve(response);
            });
        },
    },
    mutations: {
        /**
         * 
         * Marketer state management 
         * 
         */
        marketers(state, data) {
            state.marketers = data.data;
            state.mkt_meta  = data.meta;
        },
        marketer(state, data) {
            for (let index = 0; index < (state.marketers).length; index++) {
                if(state.marketers[index].id == data.id) {
                    state.marketers.splice(index,1,data);
                    return;
                }
            }
            state.marketers.unshift(data);
        },
        delete_mkt(state, data) {
            for (let index = 0; index < (state.marketers).length; index++) {
                if(state.marketers[index].id == data.id) {
                    state.marketers.splice(index,1)
                    return;
                }
            }
        },
        /**
         * 
         * Business state management
         * 
         */
        businesses(state, data) {
            state.business  = data.data
            state.bus_meta  = data.meta
        },
        business(state, data) {
            for (let index = 0; index < (state.business).length; index++) {
                if(state.business[index].id == data.id) {
                    state.business.splice(index,1,data);
                    return;
                }
            }
            state.business.unshift(data);
        },
        delete_bus(state, data) {
            for (let index = 0; index < (state.business).length; index++) {
                if(state.business[index].id == data.id) {
                    state.business.splice(index,1)
                    return;
                }
            }
        },
    },
};