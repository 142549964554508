import NotesService from "@/services/market/notes.service";

export const note = {
    namespaced: true,

    state: {},

    actions: {
        getNotes({ commit }, data) {
            return NotesService.getNotes(data).then((data) => {
                commit("notes", data);
                return Promise.resolve(data);
            });
        },
    },

    mutations: {
        notes(state, data) {
            state.notes = data;
        },
    }




}