import axios from 'axios'
import  helpers  from '../helpers/helper';

    let user    = JSON.parse(localStorage.getItem("_user"));
    let token   = null;
    if (user && user.attributes.token) {
        token    = user.attributes.token;
    }

    const Axios = axios.create({
        baseURL : helpers.API_ENDPOINT,
        // timeout : 1000,
        headers : { common  : {
            'Accept'        : 'application/json',
            'Content-type'  : 'application/json',
            'Authorization' : 'Bearer '+ token,
        }}
    });

    Axios.interceptors.response.use(function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      }, function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        // Redirect to not found page
        if(error.status == 404) {
            location.href = '/404';
        }
        return Promise.reject(error);
      });
export default Axios;