import helpers from "@/helpers/helper";
import Axios from "./Axios";

const API_URL = helpers.API_ENDPOINT;

class zemoService {
    getTeam(data) {
        return Axios({
          method  : "GET",
          url     : API_URL + "team",
          data    : data,
        });
    }
    postUser(data) { 
      return Axios({
        method    : "POST",
        url       : API_URL + "team",
        data      : data,
      });
    }
    getStatistics() {
      return Axios({
        method    : "GET",
        url       : API_URL + "statistics"
      })
    }
  }
export default new zemoService();