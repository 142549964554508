import salesService from "@/services/sales.service";

export const sales = {
  namespaced: true,

  state: {},

  actions: {
    getSalesperson({ commit }, data) {
      return salesService.getSalesperson(data).then((data) => {
        commit("salesperson", data);
        return Promise.resolve(data); 
      });
    },
    getSales({ commit }, data) {
      return salesService.getSales(data).then((data) => {
        commit("sales", data);
        return Promise.resolve(data); 
      });
    },
    getSummary({ commit }, data) {
      return salesService.getSummary(data).then((data) => {
        commit("summary", data);
        return Promise.resolve(data); 
      });
    },
    getAccountValues({ commit }, data) {
      return salesService.getAccountValues(data).then((data) => {
        commit("account_value", data);
        return Promise.resolve(data); 
      });
    },
    getSalesProjections({ commit }, data) {
      return salesService.getSalesProjections(data).then((data) => {
        commit("projectsion", data);
        return Promise.resolve(data); 
      });
    },
    getBreakEven({ commit }, data) {
      return salesService.getBreakEven(data).then((data) => {
        commit("break_even", data);
        return Promise.resolve(data); 
      });
    },
    getAutoGeneration({ commit }, data) {
      return salesService.getAutoGeneration(data).then((data) => {
        commit("auto_generation", data);
        return Promise.resolve(data); 
      });
    },
    getEmailMatrix({ commit }, data) {
      return salesService.getEmailMatrix(data).then((data) => {
        commit("email_matrix", data);
        return Promise.resolve(data); 
      });
    },
    getClientExperiece({ commit }, data) {
      return salesService.getClientExperiece(data).then((data) => {
        commit("client_experience", data);
        return Promise.resolve(data); 
      });
    },
    getClientExperieceView({ commit }, data) {
      return salesService.getClientExperieceView(data).then((data) => {
        commit("client_experience_view", data);
        return Promise.resolve(data); 
      });
    },
    getIteration({ commit }, data) {
      return salesService.getIteration(data).then((data) => {
        commit("digital_itaration", data);
        return Promise.resolve(data); 
      });
    },
  },

  mutations: {
    salesperson(state, data) {
      state.salesperson = data;
    },
    sales(state, data) {
      state.sales = data;
    },
    summary(state, data) {
      state.summary = data;
    },
    account_value(state, data) {
      state.account_value = data;
    },
    projectsion(state, data) {
      state.projectsion = data;
    },
    break_even(state, data) {
      state.break_even = data;
    },
    auto_generation(state, data) {
      state.auto_generation = data;
    },
    email_matrix(state, data) {
      state.email_matrix = data;
    },
    client_experience(state, data) {
      state.client_experience = data;
    },
    client_experience_view(state, data) {
      state.client_experience_view = data;
    },
    digital_itaration(state, data) {
      state.digital_itaration = data;
    },
  }
}
