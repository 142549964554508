import StagesService from "@/services/settings/stages.service";

export const stage = {
    namespaced: true,

    state: {},

    actions: {
        getStages({ commit }, data) {
            return StagesService.getStages(data).then((data) => {
                commit("stages", data);
                return Promise.resolve(data);
            });
        },
    },

    mutations: {
        stages(state, data) {
            state.stages = data;
        }
    }

}