import axios from "axios";
import { _authHeader } from "../auth._header";
import helpers from "@/helpers/helper";

const API_URL = helpers.API_ENDPOINT;

class DealService {
    getDeals(data) {
        let data_check = data;
        if (data.saler_id == 0) {
            data_check.saler_id = '';
        } else {
            data_check.saler_id = data.saler_id;
        }
        if (data.status == 3) {
            data_check.status = '';
        } else {
            data_check.status = data.status;
        }
        return axios.get(API_URL + "deals", { params: helpers.params(data_check), headers: _authHeader(), }).then((response) => {
            return response.data;
        });
    };

    getDealShow(data) {
        return axios.get(API_URL + "deal-show/" + data.id, { headers: _authHeader(), }).then((response) => {
            return response.data;
        });
    };

    downloadExcel(data) {
        let user = JSON.parse(localStorage.getItem("_user"))
        if (user) {
            return axios.post(`${API_URL}download-company-list-excel`, data, {
                headers: { Authorization: "Bearer " + user.attributes.token, timeout: 1000 },
                responseType: "arraybuffer",
            })
        }
    }

}

export default new DealService();