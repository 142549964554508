import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import moment from "moment";
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import HighchartsVue from 'highcharts-vue'
import helpers from './helpers/helper'
import apiEndpoints from './services/api.endpoints'
import CKEditor from '@ckeditor/ckeditor5-vue';
// import 'element-plus/lib/theme-chalk/index.css';

var show = true;

const app = createApp(App).use(store).use(moment).use(router).use(ElementPlus).use(HighchartsVue).use( CKEditor );
app.config.globalProperties.$moment = moment;
app.config.globalProperties.$helpers = helpers;
app.config.globalProperties.$show = show;
app.config.globalProperties.$apiEndpoints = apiEndpoints;

app.mount("#app");
