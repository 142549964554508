import interestedService from "@/services/interested.service";

export const interested = {
    namespaced      : true,
    state   : {
        interested  : [],
        meta        : []
    },
    actions : {
        getInterested({ commit }, data) {
            return interestedService.getInterested(data).then((response) => {
                commit("interested", response.data);
                return Promise.resolve(response);
            });
        },
        patchInterested({ commit }, data) {
            return interestedService.patchInterested(data).then((response) => {
                commit("interest", response.data.data);
                return Promise.resolve(response);
            });
        }
    },
    mutations   : {
        interested(state, data) {
            state.interested = data.data;
            state.meta  = data.meta
        },
        interest(state,data) {
            for (let index = 0; index < (state.interested).length; index++) {
                if(state.interested[index].id == data.id) {
                    state.interested.splice(index,1,data);
                    return;
                }
            }
            state.interested.unshift(data);
        }
    },
};