import UserService from "@/services/organization/user.service";

export const user = {
    namespaced: true,

    state: {},

    actions: {
        getUser({ commit }, data) {
            return UserService.getUser(data).then((data) => {
                commit("system_user", data);
                return Promise.resolve(data);
            });
        },
        getUserSelect({ commit }, data) {
            return UserService.getUserSelect(data).then((data) => {
                commit("system_user_select", data);
                return Promise.resolve(data);
            });
        },
    },

    mutations: {
        system_user(state, data) {
            state.system_user = data;
        },
        system_user_select(state, data) {
            state.system_user_select = data;
        }
    }


}