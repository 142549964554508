import axios from "axios";
import { _authHeader } from "@/services/auth._header";
import helpers from "@/helpers/helper";

const API_URL = helpers.API_ENDPOINT;

class TicketService {
    getTicket(data) {
        let data_check = data;
        if (data.saler_id == 0) {
            data_check.saler_id = '';
        } else {
            data_check.saler_id = data.saler_id;
        }
        if (data.priority == 'all') {
            data_check.priority = '';
        } else {
            data_check.priority = data.priority;
        }
        return axios.get(API_URL + "help_desk/t_ticket", { params: helpers.params(data_check), headers: _authHeader(), }).then((response) => {
            return response.data;
        });
    };
    getClient(data) {
        return axios.post(API_URL + "help_desk/client-select-dropdown", data, {
            headers: _authHeader(),
        });
    };
    getCategory() {
        return axios.get(API_URL + "help_desk/t_category", {
            headers: _authHeader(),
        });
    };
    getStatus() {
        return axios.get(API_URL + "help_desk/t_status", {
            headers: _authHeader(),
        });
    };

    getResponse(data) {
        let data_check = data;
        if (data.saler_id == 0) {
            data_check.saler_id = '';
        } else {
            data_check.saler_id = data.saler_id;
        }
        if (data.priority == 'all') {
            data_check.priority = '';
        } else {
            data_check.priority = data.priority;
        }
        return axios.get(API_URL + "help_desk/t_response", { params: helpers.params(data_check), headers: _authHeader(), }).then((response) => {
            return response.data;
        });
    };
    getReplies(data) {
        let data_check = data;
        if (data.saler_id == 0) {
            data_check.saler_id = '';
        } else {
            data_check.saler_id = data.saler_id;
        }
        if (data.priority == 'all') {
            data_check.priority = '';
        } else {
            data_check.priority = data.priority;
        }
        return axios.get(API_URL + "help_desk/t_reply", { params: helpers.params(data_check), headers: _authHeader(), }).then((response) => {
            return response;
        });
    };

    

}

export default new TicketService();