import helpers from "@/helpers/helper";
import Axios from "./Axios";

const API_URL = helpers.API_ENDPOINT;

class prospectsService {
  getProspects(data) {
    return Axios({
      method  : "GET",
      url     : API_URL + "clients",
    });
  }
  postProspect(data) { 
    return Axios({
      method  : "POST",
      url     : API_URL + "clients",
      data    : data,
    });
  }
  patchProspect(data) { 
    return Axios({
      method  : "PATCH",
      url     : API_URL + "clients/" + data.id,
      data    : data,
    });
  }
  deleteProspect(data) { 
    return Axios({
      method  : "DELETE",
      url     : API_URL + "clients/" + data.id,
    });
  }
}
  
export default new prospectsService();