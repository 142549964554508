
import axios from "axios";
import { _authHeader } from "./auth._header";
import helpers from "@/helpers/helper";

const API_URL = helpers.API_ENDPOINT;

class SettingsService  {
    getAPIKeys(data) {
        var page = data ? data.page : 1;

        if (data.start_date == null && data.end_date == null) {
          data.start_date = "";
          data.end_date = "";
        }
        
        const params = new URLSearchParams();
        params.append('page', page);
        params.append('search', data.search);
        params.append('start_date', data.start_date);
        params.append('end_date', data.end_date); 
    
        return axios.get(API_URL + "api_key", { params: params, headers: _authHeader(), }).then((response) => {
          console.log("RRrrrrrrrrrrrrrrrrrrrrrresponse check", response.data)
          return response.data;
        });
      }
}

export default new SettingsService();

