import axios from "axios";
import { _authHeader } from "../auth._header";
import helpers from "@/helpers/helper";

const API_URL = helpers.API_ENDPOINT;

class PermissionService {
    getPermission(data) {
        return axios.get(API_URL + "permissions", { params:helpers.params(data), headers: _authHeader(), }).then((response) => {
            return response.data;
        });
    };
    
}

export default new PermissionService();