import axios from "axios";
import { _authHeader } from "../auth._header";
import helpers from "@/helpers/helper";

const API_URL = helpers.API_ENDPOINT;

class RoleService {
    getRoles(data) {
        return axios.get(API_URL + "roles", { params:helpers.params(data), headers: _authHeader(), }).then((response) => {
            return response.data;
        });
    };
    getRolesSelect() {
        return axios.get(API_URL + "role-select", {headers: _authHeader(), }).then((response) => {
            return response.data;
        });
    };
}

export default new RoleService();