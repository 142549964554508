import prospectsService from "@/services/prospects.service";

export const prospects = {
    namespaced      : true,
    state   : {
        prospects   : {},
        meta        : {},
    },
    actions : {
        getProspects({ commit }) {
            return prospectsService.getProspects().then((response) => {
                commit("prospects", response.data);
                return Promise.resolve(response);
            });
        },
        postProspect({ commit }, data) {
            return prospectsService.postProspect(data).then((response) => {
                commit("prospect", response.data.data);
                return Promise.resolve(response);
            });
        },
        patchProspect({ commit }, data) {
            return prospectsService.patchProspect(data).then((response) => {
                commit("prospect", response.data.data);
                return Promise.resolve(response);
            });
        },
        deleteProspect({ commit }, data) {
            return prospectsService.deleteProspect(data).then((response) => {
                commit("delete", data);
                return Promise.resolve(response);
            });
        },
    },
    mutations   : {
        prospects(state, data) {
            state.prospects = data.data;
            state.meta      = data.meta;
        },
        prospect(state, data) {
            for (let index = 0; index < (state.prospects).length; index++) {
                if(state.prospects[index].id == data.id) {
                    state.prospects.splice(index,1,data);
                    state.prospect = data;
                    return;
                }
            }
            Array.from(state.prospects).unshift(data);
        },
        delete(state, data) {
            for (let index = 0; index < (state.prospects).length; index++) {
                if(state.prospects[index].id == data.id) {
                    state.prospects.splice(index,1)
                    return;
                }
            }
        }
    },
};